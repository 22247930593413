import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const routesApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    credentials: 'include',
  }),
  reducerPath: 'routesApi',
  tagTypes: ['SavedRoute', 'SavedRoutes'],
  endpoints: (builder) => ({
    optimizeRoute: builder.mutation({
      query: (body) => ({
        url: 'routes/optimize',
        method: 'POST',
        body,
      }),
    }),
    shareRoute: builder.mutation({
      query: (body) => ({
        url: 'routes/share',
        method: 'POST',
        body,
      }),
    }),
    getSavedRoutes: builder.query({
      query: () => 'user/routes',
      providesTags: ['SavedRoutes'],
    }),
    getRoute: builder.query({
      query: (idRoute) => `routes/${idRoute}`,
      providesTags: ['SavedRoute'],
    }),
    getRouteById: builder.mutation({
      query: (idRoute) => `routes/${idRoute}`,
      providesTags: ['SavedRoute'],
    }),
    saveRoute: builder.mutation({
      query: (body) => ({
        url: 'user/routes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SavedRoute', 'SavedRoutes'],
    }),
    updateSavedRoute: builder.mutation({
      query: (body) => ({
        url: 'user/routes',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SavedRoute', 'SavedRoutes'],
    }),
    deleteSavedRoute: builder.mutation({
      query: (idRoute) => ({
        url: `user/routes/${idRoute}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SavedRoutes'],
    }),
  }),
});

export const {
  useDeleteSavedRouteMutation,
  useGetSavedRoutesQuery,
  useGetRouteQuery,
  useGetRouteByIdMutation,
  useOptimizeRouteMutation,
  useSaveRouteMutation,
  useUpdateSavedRouteMutation,
  useShareRouteMutation,
  util: { resetApiState },
} = routesApi;
