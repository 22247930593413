// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  reducerPath: 'userApi',
  tagTypes: ['User'],
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: 'auth/password/forgot',
        method: 'POST',
        body,
      }),
    }),
    getUser: builder.query({
      query: () => 'user',
      providesTags: ['User'],
    }),
    login: builder.mutation({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
      providesTags: ['User'],
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    register: builder.mutation({
      query: (body) => ({
        url: 'auth/signup',
        method: 'POST',
        body,
      }),
      providesTags: ['User'],
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: 'auth/password/reset',
        method: 'POST',
        body,
      }),
    }),
    updatePreferences: builder.mutation({
      query: (body) => ({
        url: 'user/preferences',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['User'],
      providesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: 'user',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useForgotPasswordMutation,
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useUpdatePreferencesMutation,
  useUpdateUserMutation,
} = userApi;
