import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import localforage from 'localforage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import globalReducer from 'services/globalSlice';
import { paymentMethodApi } from 'services/paymentMethod';
import { globalSliceTransform } from 'services/reduxPersistTransforms';
import { routesApi } from 'services/routes';
import { rtkAnalyticsListenerMiddleware } from 'services/rtkAnalyticsListenerMiddleware';
import { rtkErrorListenerMiddleware } from 'services/rtkErrorListenerMiddleware';
import { rtkSuccessListenerMiddleware } from 'services/rtkSuccessListenerMiddleware';
import { subscriptionApi } from 'services/subscription';
import { userApi } from 'services/user';

const combinedReducers = combineReducers({
  global: globalReducer,
  // Add the generated reducer as a specific top-level slice
  [paymentMethodApi.reducerPath]: paymentMethodApi.reducer,
  [routesApi.reducerPath]: routesApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage: localforage,
  transforms: [globalSliceTransform],
  whitelist: ['global'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'global/setModalOptions'],
        ignoredPaths: ['global.modalOptions.onClose'],
      },
    })
      .concat(rtkAnalyticsListenerMiddleware.middleware)
      .concat(rtkErrorListenerMiddleware.middleware)
      .concat(rtkSuccessListenerMiddleware.middleware)
      .concat(paymentMethodApi.middleware)
      .concat(routesApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(userApi.middleware),
});

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
