import { createListenerMiddleware, isFulfilled } from '@reduxjs/toolkit';

import { configureGAUserId, getGAClientId, trackAnalytics } from 'common/analytics';
import { toTitleCase } from 'common/toTitleCase';
import { userApi } from 'services/user';

export const rtkAnalyticsListenerMiddleware = createListenerMiddleware();

rtkAnalyticsListenerMiddleware.startListening({
  matcher: isFulfilled,
  effect: async (action, { dispatch }) => {
    switch (action?.meta?.arg?.endpointName) {
      // Authentication Actions
      case 'forgotPassword':
        trackAnalytics({
          eventName: 'forgot_password',
          parameters: {
            event_category: 'Authentication',
            event_label: 'Forgot Password',
          },
        });
        break;
      case 'login':
        trackAnalytics({
          eventName: 'login',
          parameters: {
            event_category: 'Authentication',
            event_label: 'Login',
          },
        });
        configureGAUserId(action.payload.accountDetails.id);
        getGAClientId((gaClientId) => {
          dispatch(userApi.endpoints.updateUser.initiate({ analyticsId: gaClientId }));
        });
        break;
      case 'register':
        trackAnalytics({
          eventName: 'sign_up',
          parameters: {
            event_category: 'Authentication',
            event_label: 'Sign Up',
          },
        });
        configureGAUserId(action.payload.accountDetails.id);
        getGAClientId((gaClientId) => {
          dispatch(userApi.endpoints.updateUser.initiate({ analyticsId: gaClientId }));
        });
        break;
      case 'resetPassword':
        trackAnalytics({
          eventName: 'reset_password',
          parameters: {
            event_category: 'Authentication',
            event_label: 'Reset Password',
          },
        });
        break;

      // Route Actions
      case 'deleteSavedRoute':
        trackAnalytics({
          eventName: 'delete_saved_route',
          parameters: {
            event_category: 'Routes',
            event_label: 'Delete Saved Route',
          },
        });
        break;
      case 'getRoute':
        trackAnalytics({
          eventName: 'get_saved_route',
          parameters: {
            event_category: 'Routes',
            event_label: 'Get Saved Route',
          },
        });
        break;
      case 'getRouteById':
        trackAnalytics({
          eventName: 'get_saved_route',
          parameters: {
            event_category: 'Routes',
            event_label: 'Get Saved Route By Id',
          },
        });
        break;
      case 'optimizeRoute':
        trackAnalytics({
          eventName: 'optimizeRoute',
          parameters: {
            event_category: 'Routes',
            event_label: 'Optimize Route',
            travel_mode: action.meta.arg.originalArgs.travelMode,
            num_waypoints: action.meta.arg.originalArgs.waypoints.length,
            original_distance: action.payload.originalRoute.intervalValues.distanceMeters,
            original_duration: action.payload.originalRoute.intervalValues.durationSeconds,
            optimized_distance: action.payload.optimizedRouteTotals.distanceMeters,
            optimized_duration: action.payload.optimizedRouteTotals.durationSeconds,
            distance_delta: action.payload.optimizationDeltas.distanceMeters,
            duration_delta: action.payload.optimizationDeltas.durationSeconds,
            num_routes: action.payload.totalRoutes,
          },
        });
        break;
      case 'saveRoute':
        trackAnalytics({
          eventName: 'save_route',
          parameters: {
            event_category: 'Routes',
            event_label: 'Save Route',
          },
        });
        break;
      case 'shareRoute':
        trackAnalytics({
          eventName: 'share_route',
          parameters: {
            event_category: 'Routes',
            event_label: 'Share Route',
          },
        });
        break;
      case 'updateSavedRoute':
        trackAnalytics({
          eventName: 'rename_route',
          parameters: {
            event_category: 'Routes',
            event_label: 'Rename Route',
          },
        });
        break;

      // Subscription Actions
      case 'cancelSubscription':
        trackAnalytics({
          eventName: 'subscription_cancellation',
          parameters: {
            event_category: 'Subscription',
            event_label: 'Cancel Subscription',
            subscription_type: toTitleCase(action.meta.arg.originalArgs.lookupKey.replace('_', ' - ')),
            change_effective_date: action.meta.arg.originalArgs.periodEndDate.split('T')[0],
          },
        });
        break;
      case 'updateSubscription':
        trackAnalytics({
          eventName: 'subscription_change',
          parameters: {
            event_category: 'Subscription',
            event_label: 'Change Subscription',
            old_subscription_type: toTitleCase(action.meta.arg.originalArgs.oldLookupKey.replace('_', ' - ')),
            new_subscription_type: toTitleCase(action.meta.arg.originalArgs.newLookupKey.replace('_', ' - ')),
          },
        });
        break;

      // User Actions
      case 'getUser':
        configureGAUserId(action.payload.accountDetails.id);
        break;
      case 'updatePreferences':
        trackAnalytics({
          eventName: 'update_preferences',
          parameters: {
            event_category: 'User Profile',
            event_label: 'Update Preferences',
          },
        });
        break;
      case 'updateUser':
        trackAnalytics({
          eventName: 'update_user',
          parameters: {
            event_category: 'User Profile',
            event_label: 'Update User',
          },
        });
        break;

      default:
        break;
    }
  },
});
